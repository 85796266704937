<template>
  <div class="page">
    <!-- v-resize="onResize" -->
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          style="padding: 14px 20px"
          class="capitalize"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text color="success" v-on="on">
              <v-icon size="25" dark>mdi-bell-outline</v-icon>
              <v-badge
                bordered
                content="3"
                overlap
                style="margin: 5px 10px 0px 10px"
              ></v-badge>
            </v-btn>
          </template>
          <span>Request Response Notification</span>
        </v-tooltip> -->
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-row>
        <v-col cols="12" :sm="disleft">
          <div class="searching">
            <v-text-field
              hide-details
              item-color="blue"
              :loading="load.text"
              label="Search For Practitioners"
              outlined
              rounded
              v-model="search.text"
              clearable
              shaped
              @click:clear="clearSearch"
            ></v-text-field>

            <div class="seaTitle">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                    outlined
                    :loading="load.category"
                    dense
                    label="Select Category"
                    :items="subscriberTypeList"
                    item-text="name"
                    item-value="id"
                    v-model="search.category"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    :disabled="disable.specialize"
                    outlined
                    dense
                    label="Select Profession"
                    hide-details
                    :items="specialtyList"
                    item-text="name"
                    item-value="id"
                    v-model="search.specialty"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    :disabled="disable.locations"
                    outlined
                    dense
                    label="filter by city"
                    hide-details
                    v-model="search.city"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>

          <v-card flat style="margin-top: 5px" v-if="searchList.length > 0">
            <v-card-text style="padding: 0px">
              <v-data-table
                :headers="headers"
                :items="searchList"
                :sort-by="['ratings']"
                :sort-desc="[true]"
                must-sort:
                true
                :items-per-page="7"
                :footer-props="{
                  'items-per-page-options': [5, 10],
                }"
              >
                <template v-slot:item.fullname="{ item }">
                  {{ item.salutation | capitalize }}
                  {{ item.first_name | capitalize }}
                  {{ item.mid_name | capitalize }}
                  {{ item.last_name | capitalize }}
                </template>
                <template v-slot:item.ratings="{ item }">
                  <v-rating
                    dense
                    readonly
                    v-model="item.ratings"
                    background-color="rgb(255, 225, 206)"
                    color="rgb(255, 103, 2)"
                    small
                  ></v-rating>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="
                          detail(
                            item.subscribersid,
                            item.salutation,
                            item.subscribersid,
                            item.first_name,
                            item.mid_name,
                            item.last_name,
                            item.ratings,
                            item.business_name,
                            item.my_pix
                          )
                        "
                        class="mx-2"
                        v-on="on"
                        fab
                        text
                        small
                        color="primary"
                      >
                        <v-icon dark>mdi-card-account-details-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>View Details</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <br />
              <!-- start -->
              <!-- end -->
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" v-show="disRight" sm="6">
          <v-card flat outlined>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4" style="padding: 10px 10px">
                  <v-img
                    :src="selected.pix"
                    max-width="150"
                    min-height="170"
                    max-height="170"
                    contain
                  ></v-img>
                </v-col>
                <v-col cols="12" sm="8" class="offersDetail">
                  <div class="mylist">
                    <ul>
                      <li>
                        <div class="myleft">name</div>
                        <div class="myright capitalize">
                          {{ selected.salutationsName }}
                          {{ selected.first_name }}
                          {{ selected.mid_name }}
                          {{ selected.last_name }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">business</div>
                        <div class="myright capitalize">
                          {{ selected.business_name }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">
                          rating
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <span v-on="on" style="padding: 1px 0px 0px 5px">
                                ({{ reviewTotal }})
                              </span>
                            </template>
                            <span>Total Subscribers rating this medic</span>
                          </v-tooltip>
                        </div>
                        <div class="myright">
                          <v-rating
                            dense
                            readonly
                            v-model="selected.ratings"
                            background-color="rgb(255, 225, 206)"
                            color="rgb(255, 103, 2)"
                            small
                          ></v-rating>
                        </div>
                        <div class="clearall"></div>
                      </li>
                    </ul>
                  </div>
                  <v-toolbar flat dense style="padding: 0px !important">
                    <v-btn
                      block
                      small
                      depressed
                      color="primary"
                      :to="{
                        name: 'patient.request.date',
                        params: {
                          id: _encode(this.selected.id),
                          salute: _encode(this.selected.salutationsName),
                          first: _encode(this.selected.first_name),
                          last: _encode(this.selected.last_name),
                        },
                      }"
                    >
                      make a request
                    </v-btn>
                  </v-toolbar>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- <div v-if="isMore === true"> -->
          <v-card
            flat
            style="margin-top: 5px"
            :loading="loadcat"
            loader-height="1"
          >
            <v-card-text>
              <v-tabs
                v-model="moretab"
                centered
                grow
                style="margin-bottom: 10px"
              >
                <v-tab key="experience"> experience </v-tab>
                <v-tab key="reviews">client reviews</v-tab>
              </v-tabs>
              <v-tabs-items v-model="moretab">
                <v-tab-item key="experience">
                  <v-data-iterator
                    :items="previewList"
                    item-key="id"
                    :items-per-page="2"
                  >
                    <template v-slot:default="{ items }">
                      <v-row>
                        <v-col
                          v-for="i in items"
                          :key="i.id"
                          cols="12"
                          sm="12"
                          md="12"
                          lg="12"
                        >
                          <v-card flat outlined>
                            <v-card-text>
                              <div class="mylist">
                                <ul>
                                  <li>
                                    <div class="myleft">current work</div>
                                    <div class="myright">
                                      {{ i.current_work }}
                                    </div>
                                    <div class="clearall"></div>
                                  </li>
                                  <li>
                                    <div class="myleft">experience</div>
                                    <div class="myright">
                                      {{ i.experience }}
                                    </div>
                                    <div class="clearall"></div>
                                  </li>
                                  <li>
                                    <div class="myleft">specialization</div>
                                    <div class="myright">
                                      {{ i.specialization }}
                                    </div>
                                    <div class="clearall"></div>
                                  </li>
                                  <li>
                                    <div class="myleft">gained from</div>
                                    <div class="myright">
                                      {{ i.gained_from }}
                                    </div>
                                    <div class="clearall"></div>
                                  </li>
                                </ul>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                    <!--  -->
                  </v-data-iterator>
                </v-tab-item>
                <v-tab-item key="reviews">
                  <v-data-iterator
                    :items="reviewList"
                    item-key="id"
                    :items-per-page="2"
                  >
                    <template v-slot:default="{ items }">
                      <v-row>
                        <v-col
                          v-for="i in items"
                          :key="i.id"
                          cols="12"
                          sm="12"
                          md="12"
                          lg="12"
                        >
                          <v-card flat outlined>
                            <v-card-text style="padding: 0px">
                              <!-- start -->
                              <v-list flat subheader three-line>
                                <v-list-item-group multiple>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        style="font-size: 0.9em"
                                      >
                                        {{ i.user }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        {{ i.message }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-rating
                                        dense
                                        hover
                                        background-color="rgb(255, 225, 206)"
                                        color="rgb(255, 103, 2)"
                                        length="5"
                                        :value="i.rating"
                                        small
                                      ></v-rating>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                              <!-- end -->
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                    <!--  -->
                  </v-data-iterator>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import Util from "../../mixins/utils";

export default {
  components: {},
  mixins: [Util, WinScroll("position")],
  data() {
    return {
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "patient.dash" },
        },
        {
          exact: true,
          link: true,
          text: "Schedules",
          to: { name: "patient.request" },
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      caSelect: null,
      disableLocs: true,
      searchList: [],
      headers: [
        { text: "name", value: "fullname" },
        { text: "specialization", value: "specialization" },
        { text: "business name", value: "business_name" },
        { text: "city", value: "city" },
        { text: "", value: "actions" },
      ],
      disleft: 12,
      disRight: false,
      specializationList: [],
      //
      requestDialog: false,
      requestDate: null,
      moretab: null,
      detailHeight: 0,
      showslot: false,
      isMedics: false,
      isMore: false,
      isMakeRequest: false,
      subscriberTypeList: [],
      regionsList: [],
      load: {
        text: false,
        category: false,
      },
      disable: {
        specialize: true,
        locations: true,
      },
      search: {
        text: null,
        category: null,
        specialty: null,
        city: null,
      },
      loadevent: false,
      searchMedicList: [],
      specialtyList: [],
      specialDetailList: [],
      previewList: [],
      reviewList: [],
      reviewTotal: 0,
      selected: {
        id: null,
        salutationsName: null,
        subscribersid: null,
        first_name: null,
        mid_name: null,
        last_name: null,
        ratings: null,
        business_name: null,
        pix: null,
      },
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    "search.text": function (a) {
      if (a === null || a.length === 0) {
        console.log("empty");
        this.clearSearch();
      } else {
        console.log("go", a);
        this.searchText(a);
        this.subscrberTypes();
      }
    },
    "search.category": function (a) {
      console.log("a", a);
      this.searchBySubType(this.search.text, a);
      this.specialization(a);
    },
    "search.specialty": function (q) {
      console.log(q);
      this.specialdetails(q);
    },
    "search.city": function (q) {
      if (q === null || q.length === 0) {
        this.specialdetails(this.search.specialty);
      } else {
        console.log(q);
        this.locations(q);
      }
    },
  },
  created() {
    this.regions();
    this.subscrberTypes();
  },
  methods: {
    searchText(a) {
      console.log(a);
      let text = a;
      let data = { text };
      this.load.text = true;
      let self = this;
      Restful.searching
        .searchText(data)
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            this.searchList = response.data.subscribers;
          }
          this.load.text = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.load.text = false;
        });
    },
    clearSearch() {
      this.searchList = [];
      this.subscriberTypeList = [];
      this.specialtyList = [];
      this.disleft = 12;
      this.disRight = false;
    },
    subscrberTypes() {
      this.loadcat = true;
      let self = this;
      Restful.general.subscriberTypes
        .nonPatient()
        .then((response) => {
          console.log(response);
          this.subscriberTypeList = response.data;
          this.loadcat = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loadcat = false;
        });
    },
    searchBySubType(a, sub) {
      this.load.category = true;
      let text = a;
      let subscribertype = sub;
      let data = { text, subscribertype };

      let self = this;
      Restful.searching
        .subType(data)
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            this.searchList = response.data.subscribers;
            this.disable.specialize = false;
          }
          this.load.category = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.load.category = false;
        });
    },
    specialization(id) {
      this.disablePro = false;
      let self = this;
      Restful.general.specialty
        .searchBySubType(id)
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            this.specialtyList = response.data;
            this.disable.locations = false;
          }
          this.loadcat = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loadcat = false;
        });
    },
    specialdetails(sp) {
      let text = this.search.text;
      let subscribertype = this.search.category;
      let specialty = sp;

      console.log("text", text);
      console.log("subscribertype", subscribertype);
      console.log("specializationid", specialty);

      let data = { text, subscribertype, specialty };

      this.loadcat = true;
      let self = this;
      Restful.searching
        .searchBySpecialization(data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.searchList = response.data.subscribers;
            this.loadcat = false;
          }
          this.loadcat = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loadcat = false;
        });
    },
    locations(loc) {
      let text = this.search.text;
      let subscribertype = this.search.category;
      let specialty = this.search.specialty;
      let city = loc;

      let data = { text, subscribertype, specialty, city };
      console.log("data", data);

      this.loadcat = true;
      let self = this;
      Restful.searching
        .searchByCity(data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.searchList = response.data.subscribers;
            this.loadcat = false;
          }
          this.loadcat = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loadcat = false;
        });
    },
    //
    subscribersPreview() {
      this.loadcat = true;
      let self = this;
      Restful.subscribers.background
        .preview(this.selected.id)
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            this.previewList = response.data;
          }
          this.loadcat = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loadcat = false;
        });
    },
    reviews(id) {
      this.loadcat = true;
      let self = this;
      Restful.accounts.reviews
        .on(id)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.reviewList = response.data;
            this.reviewTotal = response.data.length;
            console.log(response.data.length);
          }
          this.loadcat = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loadcat = false;
        });
    },
    regions() {
      this.disregion = true;
      let self = this;
      Restful.general.regions
        .byCountry(1)
        .then((response) => {
          console.log(response);
          this.regionsList = response.data;
          this.disregion = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.disregion = false;
        });
    },
    specialties() {
      this.loadcat = true;
      let self = this;
      Restful.general.specialty
        .list()
        .then((response) => {
          console.log(response);
          this.specialtyList = response.data;
          this.loadcat = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loadcat = false;
        });
    },
    searchMedic() {
      let subscriberType = this.search.category;
      let regionId = this.search.regions;
      let specializationId = this.search.specialty;
      let specialDetailId = this.search.spedetail;
      let data = {
        subscriberType,
        specializationId,
        specialDetailId,
        regionId,
      };
      let self = this;
      console.log(data);
      Restful.medics.request
        .patientRequest(data)
        .then((response) => {
          if (response.status === 200) {
            this.searchMedicList = response.data;
            console.log("data: ", this.searchMedicList);
            this.disregion = false;
          } else {
            this.searchMedicList = [];
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.disregion = false;
        });
    },
    menulink() {
      this.$emit("sidebar");
    },
    detail(id, sal, sub, first, mid, last, rate, business, my_pix) {
      this.selected.id = id;
      this.selected.salutationsName = sal;
      this.selected.subscribersid = sub;
      this.selected.first_name = first;
      this.selected.mid_name = mid;
      this.selected.last_name = last;
      this.selected.ratings = rate;
      this.selected.business_name = business;
      this.selected.pix = my_pix;
      this.disleft = 6;
      this.disRight = true;
      setTimeout(() => {
        this.reviews(id);
        this.subscribersPreview(id);
      }, 1000);
    },
  },
};
</script>
